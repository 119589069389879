import { defineMessages } from 'react-intl'

export default defineMessages({
    placePlaceholder: {
        defaultMessage: 'Escribe tu calle o código postal',
    },
    submitButton: {
        defaultMessage: 'Buscar estación',
    },
})
