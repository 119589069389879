import React from 'react'
import PropTypes from 'prop-types'
import { Copy, Flex } from '@elparking/components'

import styled from 'styled-components'
import {
    ITV_GAS_LPG_CAR,
    ITV_DIESEL_CAR,
    ITV_MOTORCICLE,
    ITV_GAS_LPG_VAN,
    ITV_DIESEL_VAN,
    ITV_ELECTRIC_CAR,
    ITV_ELECTRIC_VAN,
} from 'commons/js/constants'

VehicleInspectionSelectedOption.propTypes = {
    label: PropTypes.node,
    value: PropTypes.string,
}

const VehicleIconContainer = styled(Flex)`
    min-width:30px;
`

const iconMap = {
    [ITV_GAS_LPG_CAR]: '/images/itv/car_itv_dropdown.svg',
    [ITV_DIESEL_CAR]: '/images/itv/car_itv_dropdown.svg',
    [ITV_ELECTRIC_CAR]: '/images/itv/car_itv_dropdown.svg',
    [ITV_GAS_LPG_VAN]: '/images/itv/van_itv_dropdown.svg',
    [ITV_DIESEL_VAN]: '/images/itv/van_itv_dropdown.svg',
    [ITV_ELECTRIC_VAN]: '/images/itv/van_itv_dropdown.svg',
    [ITV_MOTORCICLE]: '/images/itv/motorcicle_itv_dropdown.svg',
}

function VehicleInspectionSelectedOption ({ label, value }) {
    return (
        value
        ? <Flex justifyContent='start' alignItems='center' width='100%' mt='6px' mb='4px'>
            <VehicleIconContainer justifyContent='center' mr='6px'>
                <img src={iconMap[value]} width='30px' />
            </VehicleIconContainer>
            <Copy.truncate padding='0' fontWeight='book' fontSize='medium' color='gray'>{label}</Copy.truncate>
        </Flex>
        : <Flex justifyContent='start' alignItems='center' width='100%' mt='6px' mb='4px'>
            <Copy.truncate padding='0' fontWeight='book' fontSize='medium' color='gray'>{label}</Copy.truncate>
        </Flex>
    )
}

export default VehicleInspectionSelectedOption
