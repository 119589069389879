import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const OptionWrapper = styled.div`
    line-height: 100%;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    display: flex;
    align-items: center;
`

OptionWrapper.propTypes = {
    dataTest: PropTypes.string,
}

const OptionIcon = styled.img`
    display: inline-block;
    width: 30px;
    line-height: 45px;
    margin-right: 10px;
    & > img {
        width: 30px;
    }
`
const OptionText = styled.div`
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 45px;
`
const Option = ({text, icon, dataTest}) => (
    <OptionWrapper dataTest={dataTest}>
        <OptionIcon src={icon} alt={text} />
        <OptionText>{ text }</OptionText>
    </OptionWrapper>
)

Option.propTypes = {
    dataTest: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
}

export default Option
