import { Box, themeColor, themeMediaQuery } from '@elparking/components'
import styled from 'styled-components'
import { transparentize } from 'polished'

const blackColor = (props) => transparentize(0.9, themeColor('black')(props))

export default styled(Box)`
    box-shadow: 0px 0px 8px 0px ${blackColor};
    border: 1px solid ${themeColor('lightGray40')};
    position: relative;
    background: white;
    max-width: 100%;
    ${themeMediaQuery('medium')} {
        border: none;
        box-shadow: none;
    }
`
