import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Flex, Box, Icon } from '@elparking/components'
import { PLACE_TYPE } from 'commons/js/services/autocompleteService'

import PlacesSelect from 'search-box/js/ui/PlacesSelect'
import IconButton from 'search-box/js/ui/IconButton'
import ShadowBox from 'search-box/js/ui/ShadowBox'
import SeparatorBox from 'search-box/js/ui/SeparatorBox'
import BookingDatesFlex from 'search-box/js/ui/BookingDatesFlex'

import messages from './messages'
import VehicleTypeSelector from '../VehicleTypeSelector'

class VehicleInspectionForm extends Component {
    static propTypes = {
        intl: PropTypes.object,
        autocompleteService: PropTypes.func,
        onSelect: PropTypes.func,
        onChangePlace: PropTypes.func,
        onChangeVehicleType: PropTypes.func,
        formSelector: PropTypes.object,
    }

    static defaultProps = {
        onSelect: () => {},
        onChangePlace: () => {},
        onChangeVehicleType: () => {},
        formSelector: null,
    }

    state = {
        dateType: PLACE_TYPE,
        place: null,
        vehicleType: null,
    }

    onChangePlace (place) {
        this.setState(
            () => ({ place }),
            () => {
                this.state.place && this.vehicleTypeSelect && this.vehicleTypeSelect.focus()
                this.props.onChangePlace(this.state.place)
            }
        )
    }
    onChangePlace = this.onChangePlace.bind(this)

    onChangeVehicleType (vehicleType) {
        this.setState(
            () => ({ vehicleType }),
            () => { this.props.onChangeVehicleType(vehicleType) }
        )
    }

    onChangeVehicleType = this.onChangeVehicleType.bind(this)

    onSelect (event) {
        event && event.preventDefault()

        const { place, vehicleType } = this.state

        this.props.onSelect({
            vehicleType,
            placeId: place && place.value,
        })
    }
    onSelect = this.onSelect.bind(this)

    renderPlacesSelect () {
        const { intl, autocompleteService } = this.props
        const { place } = this.state

        return (
            <PlacesSelect
              dataTest='vehicle-inspection-station-search-form-places'
              icon='len'
              showGoogleBanner
              value={place}
              autocompleteService={autocompleteService}
              placeholder={intl.formatMessage(messages.placePlaceholder)}
              onChange={this.onChangePlace}
              optionRenderer={this.renderPlacesSelectOption}
            />
        )
    }

    renderPlacesSelectOption ({ label, value }) {
        return (
            <Flex alignItems='center'>
                <Icon type='location' color='gray' align='baseline' /> <Box pl='0.5em'>{label}</Box>
            </Flex>
        )
    }

    renderVehicleTypeSelect () {
        return (
            <Box w='100%'>
                <VehicleTypeSelector
                  ref={(el) => { this.vehicleTypeSelect = el }}
                  onChange={this.onChangeVehicleType}
                  defaultValue={this.state.vehicleType}
                />
            </Box>
        )
    }

    renderFormSelector () {
        const { formSelector } = this.props
        if (formSelector) {
            return (<Box pl={['0px', '10px']} flex={['0 0 auto', '0 0 auto', '0 0 auto', '0 0 220px']}>
                { formSelector }
            </Box>)
        }

        return null
    }

    render () {
        const { intl } = this.props
        return (
            <div>
                <Flex as='form' flexWrap={['wrap', 'wrap', 'nowrap']} alignItems='stretch'>
                    <ShadowBox flex={['1 0 100%', '1 0 100%', '1 0 auto']} my={['8px', '8px', '10px', '0']}>
                        <Flex alignItems='center'>
                            {this.renderFormSelector()}
                            <Box flex={['1 0 auto', '1 0 auto', '1 0 auto', '1 0 auto']}>
                                {this.renderPlacesSelect()}
                            </Box>
                        </Flex>
                    </ShadowBox>
                    <ShadowBox flex={['1 0 100%', '1 0 100%', '0 0 320px']} my={['8px', '8px', '0']}>
                        <BookingDatesFlex flexWrap='wrap' alignItems='center'>
                            <SeparatorBox flex={['1 0 100%']}>
                                {this.renderVehicleTypeSelect()}
                            </SeparatorBox>
                        </BookingDatesFlex>
                    </ShadowBox>
                    <Box flex={['1 0 100%', '1 0 100%', '0 0 80px']} my={['8px', '8px', '0']}>
                        <IconButton dataTest='itv-search-form-search-button' type='right' size='xLarge' color='white' onClick={this.onSelect} className='js-submit'>
                            <span>{intl.formatMessage(messages.submitButton)}</span> <Icon type='right' size='xLarge' color='white' />
                        </IconButton>
                    </Box>
                </Flex>
            </div>
        )
    }
}

export default injectIntl(VehicleInspectionForm)
