import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Flex, Box, Button, Copy, Icon, themeColor } from '@elparking/components'
import { PLACE_TYPE } from 'commons/js/services/autocompleteService'

import PlacesSelect from 'search-box/js/ui/PlacesSelect'
import styled from 'styled-components'
import messages from './messages'

import { transparentize } from 'polished'

const blackColor = (props) => transparentize(0.9, themeColor('black')(props))

const ShadowBox = styled(Box)`
    box-shadow: 0px 0px 8px 0px ${blackColor};
    border: 1px solid ${themeColor('lightGray40')};
    position: relative;
    background: white;
    max-width: 100%;
`

class ElectricChargeForm extends Component {
    static propTypes = {
        intl: PropTypes.object,
        autocompleteService: PropTypes.func,
        onSelect: PropTypes.func,
        onChangePlace: PropTypes.func,
    }

    static defaultProps = {
        onSelect: () => {},
        onChangePlace: () => {},
        onChangeVehicleType: () => {},
        formSelector: null,
    }

    state = {
        dateType: PLACE_TYPE,
        place: null,
    }

    onChangePlace (place) {
        this.setState(
            () => ({ place }),
            () => {
                this.state.place && this.vehicleTypeSelect && this.vehicleTypeSelect.focus()
                this.props.onChangePlace(this.state.place)
            }
        )
    }
    onChangePlace = this.onChangePlace.bind(this)

    onSelect (event) {
        event && event.preventDefault()

        const { place, vehicleType } = this.state

        this.props.onSelect({
            vehicleType,
            placeId: place && place.value,
        })
    }
    onSelect = this.onSelect.bind(this)

    renderPlacesSelect () {
        const { intl, autocompleteService } = this.props
        const { place } = this.state

        return (
            <PlacesSelect
              dataTest='electric-charge-search-form-places'
              icon='location'
              showGoogleBanner
              maxHeight='55px'
              value={place}
              autocompleteService={autocompleteService}
              placeholder={intl.formatMessage(messages.placePlaceholder)}
              onChange={this.onChangePlace}
              optionRenderer={this.renderPlacesSelectOption}
            />
        )
    }

    render () {
        const { intl } = this.props
        return (
            <div>
                <ShadowBox flex='1 0 100%' mb={['0.5em', '1em']}>
                    <Flex alignItems='center'>
                        <Box flex='1 0 auto'>
                            {this.renderPlacesSelect()}
                        </Box>
                    </Flex>
                </ShadowBox>
                <Box>
                    <Button width='100%' height={['40px', '55px']} fontWeight='book' fontSize='medium' size='xLarge' color='white' onClick={this.onSelect} className='js-submit'>
                        {intl.formatMessage(messages.submitButton)}
                        <Copy as='span' padding='0 0 0 2em'><Icon type='right' size='xLarge' color='white' /></Copy>
                    </Button>
                </Box>
            </div>
        )
    }
}

export default injectIntl(ElectricChargeForm)
