import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Copy, Dropdown, Selector, NativeSelector, Selected, SelectorOptionsBlock, themeColor, themeFontWeight } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import { formatDay, parseMoment } from 'commons/js/util/dateConverter'
import { YEAR_MONTH_LONG_FORMAT, TIMESTAMP_FORMAT } from 'commons/js/constants'
import styled from 'styled-components'

export function makeSelectorOptions () {
    return Array.from({ length: 6 }, (_, index) => {
        const value = parseMoment().add(index, 'month')

        return ({
            label: formatDay(value, YEAR_MONTH_LONG_FORMAT),
            value: formatDay(value.endOf('month'), TIMESTAMP_FORMAT),
        })
    })
}

const Container = styled.div`
    outline: none;
    width: 100%;
`

const MonthSelectorOptionsBlock = styled(SelectorOptionsBlock)`
    border-top: 3px solid ${themeColor('main')};
`

const MonthSelectorContainer = styled(Selector)`
    border: 0;
    background: ${themeColor('white')};
    box-shadow: none;
`

const MonthSelected = styled(Selected)`
    height: 75px;
    line-height: 80px;
    text-transform: capitalize;
    width: 100%;
    font-weight: ${themeFontWeight('book')};
`

const MonthNativeSelectorContainer = styled(NativeSelector)`
    border: 0;
    color: ${themeColor('gray')};
    background: ${themeColor('white')};
`

class MonthSelector extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        defaultValue: PropTypes.any,
    }

    static defaultProps = {
        defaultValue: null,
        onFocus: () => {},
    }

    state = {
        focused: false,
    }

    focus () {
        this.props.onFocus()
        this.dropdown.onOpen()
        this.dropdown.focus()
    }

    focus = this.focus.bind(this)

    onChange (opt) {
        opt
           ? this.props.onChange(parseMoment(opt.value, TIMESTAMP_FORMAT))
           : this.props.onChange(null)
    }

    onChange = this.onChange.bind(this)

    render () {
        const { defaultValue } = this.props

        return <Container tabIndex={-1} onFocus={this.focus}>
            <Dropdown
              ref={(el) => { this.dropdown = el }}
              onChange={this.onChange}
              options={makeSelectorOptions()}
              isOpen={this.state.focused}
              selectedElement={MonthSelected}
              selectorElement={MonthSelectorContainer}
              selectorOptionsBlockElement={MonthSelectorOptionsBlock}
              nativeSelectorComponent={MonthNativeSelectorContainer}
              iconPlaceholderElement={() => null}
              value={defaultValue}
              renderOption={({ label }) => (<Copy padding='0' transform='capitalize' fontWeight='regular' size='small'>{ label }</Copy>)}
              renderPlaceholder={() => <FormattedMessage defaultMessage='Mes del evento' />}
            />
        </Container>
    }
}

export default MonthSelector
