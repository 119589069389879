import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Icon } from '@elparking/components'
import styled from 'styled-components'
import Option from './Option'

const mainColor = props => props.theme.colors.main || 'red'
const grayColor = props => props.theme.colors.gray || 'red'
const gray20 = props => props.theme.colors.gray20 || 'red'
const whiteColor = props => props.theme.colors.white || 'white'
const mediaScreenDesktop = props => `@media screen and (min-width: ${props.theme.screens.xLarge})`

const Container = styled.div`
    width: 70px;
    height: 35px;
    max-width: 100%;
    margin: 10px 0;
    background: white;
    border-right: 1px solid ${gray20};
    display: inline-block;

    ${mediaScreenDesktop} {
        width: 220px;
    }
`

const Selector = styled.div`
    border: 0px;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
`

const SelectorOption = styled.div`
    width: calc(100% - 20px);
    overflow: hidden;
    cursor: pointer;
    margin: 10px 10px 0px 10px;
    height: 60px;
    outline: none;
    border-bottom: 1px solid ${gray20};
    color: ${grayColor};
    &:hover *, &:hover i:before {
        font-weight: 300px;
        color: ${mainColor};
    }
    &:last-child {
        border-bottom: 0;
    }
`

const SelectorOptionsBlock = styled.div`
    width: 220px;
    z-index: 10;
    position: absolute;
    border-bottom: 1px solid #EEE;
    box-shadow: 0px 8px 24px 0px #EEE;
    background: ${whiteColor};
`

const IconPlaceholder = styled.div`
    position: absolute;
    top: -4px;
    right: 0;
    line-height: 3em;
    font-size: 12px;
    color: ${grayColor};
    padding: 4px;
    background: white;

    ${mediaScreenDesktop} {
        right: 5px;
    }
`

const SelectorPlaceholder = styled.div`
    height: 100%;
    padding: 0 10px;
    overflow: hidden;
    max-width: 100%;
    display: flex;
    align-items: center;
    outline: none;
`

class FormSelector extends React.Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
        })),
        onChange: PropTypes.func,
        selected: PropTypes.string,
    }

    static defaultProps = {
        options: [],
        onChange: () => {},
    }

    render () {
        const { options, onChange, selected } = this.props
        return (
            <Dropdown
              renderSelected={(option) => <Option
                dataTest='parking-search-form-places-selector-selected'
                icon={option.icon}
                text={option.label}
              />}
              renderOption={(option) => <Option
                dataTest={option.dataTest}
                icon={option.icon}
                text={option.label}
              />}
              containerElement={Container}
              selectorElement={Selector}
              selectorOptionElement={SelectorOption}
              selectorOptionsBlockElement={SelectorOptionsBlock}
              selectorPlaceholderElement={SelectorPlaceholder}
              iconPlaceholderElement={() => <IconPlaceholder><Icon type='arrow-down' size='xxSmall' color='inherit' /></IconPlaceholder>}
              options={options}
              defaultValue={selected}
              onChange={onChange}
              onlyDesktop
            />
        )
    }
}

export default FormSelector
