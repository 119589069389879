import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Dropdown, SelectorOptionsBlock, Icon, themeColor, Copy } from '@elparking/components'

import VehicleInspectionOptionsSwitcher from './VehicleInspectionOptionsSwitcher'
import VehicleInspectionSelectedOption from './VehicleInspectionSelectedOption'
import styled from 'styled-components'

import {
    ITV_GAS_LPG_CAR,
    ITV_DIESEL_CAR,
    ITV_ELECTRIC_CAR,
    ITV_MOTORCICLE,
    ITV_GAS_LPG_VAN,
    ITV_DIESEL_VAN,
    ITV_ELECTRIC_VAN,
} from 'commons/js/constants'
import VehicleInpectionSelectorMessage from 'commons/js/ui/VehicleInpectionSelectorMessage'

export function makeSelectorOptions () {
    return [
        {
            label: <VehicleInpectionSelectorMessage value={ITV_GAS_LPG_CAR} />,
            value: ITV_GAS_LPG_CAR,
        },
        {
            label: <VehicleInpectionSelectorMessage value={ITV_DIESEL_CAR} />,
            value: ITV_DIESEL_CAR,
        },
        {
            label: <VehicleInpectionSelectorMessage value={ITV_ELECTRIC_CAR} />,
            value: ITV_ELECTRIC_CAR,
        },
        {
            label: <VehicleInpectionSelectorMessage value={ITV_GAS_LPG_VAN} />,
            value: ITV_GAS_LPG_VAN,
        },
        {
            label: <VehicleInpectionSelectorMessage value={ITV_DIESEL_VAN} />,
            value: ITV_DIESEL_VAN,
        },
        {
            label: <VehicleInpectionSelectorMessage value={ITV_ELECTRIC_VAN} />,
            value: ITV_ELECTRIC_VAN,
        },
        {
            label: <VehicleInpectionSelectorMessage value={ITV_MOTORCICLE} />,
            value: ITV_MOTORCICLE,
        },
    ]
}

const SelectorOptionsBlockWithBorder = styled(SelectorOptionsBlock)`
    border-top: 3px solid ${themeColor('main')};
    max-height: 300px;
`

const IconPlaceholder = styled.div`
    color: ${themeColor('main')};
`

const ITVVehicleSelector = React.forwardRef(({ onChange, defaultValue, getReference, width, renderPlaceholder, ...props }, ref) => (
    <Dropdown
      ref={ref}
      height='auto'
      width={width}
      onlyDesktop
      onChange={onChange}
      options={makeSelectorOptions()}
      defaultValue={defaultValue}
      renderOption={({ value }) => <VehicleInspectionOptionsSwitcher value={value} /> }
      renderSelected={({ label, value }) => <VehicleInspectionSelectedOption label={label} value={value} /> }
      renderPlaceholder={renderPlaceholder}
      selectorOptionsBlockElement={SelectorOptionsBlockWithBorder}
      iconPlaceholderElement={() => <IconPlaceholder><Icon type='arrow-down' size='xxSmall' color='inherit' /></IconPlaceholder>}
      {...props}
    />
))

ITVVehicleSelector.defaultProps = {
    renderPlaceholder: () => <Copy fontWeight='regular' color='lightGray' fontSize='small' padding={['0 5px', '0px']}><FormattedMessage defaultMessage='Tipo de vehículo' /></Copy>,
}

ITVVehicleSelector.propTypes = {
    onChange: PropTypes.func,
    getReference: PropTypes.func,
    defaultValue: PropTypes.string,
    width: PropTypes.string,
    renderPlaceholder: PropTypes.func,
}

export default ITVVehicleSelector
