import { defineMessages } from 'react-intl'

export default defineMessages({
    allFilterLabel: {
        defaultMessage: 'Todos los parkings',
    },
    placesFilterLabel: {
        defaultMessage: 'Parkings',
    },
    airportsFilterLabel: {
        defaultMessage: 'Aeropuertos',
    },
})
