import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
    ITV_GAS_LPG_CAR,
    ITV_DIESEL_CAR,
    ITV_ELECTRIC_CAR,
    ITV_GAS_LPG_VAN,
    ITV_DIESEL_VAN,
    ITV_ELECTRIC_VAN,
    ITV_MOTORCICLE,
} from 'commons/js/constants'

export const mapMessages = {
    [ITV_GAS_LPG_CAR]: <FormattedMessage defaultMessage='Turismo | Gasolina GNL/GNC/GLP' />,
    [ITV_DIESEL_CAR]: <FormattedMessage defaultMessage='Turismo | Diesel' />,
    [ITV_ELECTRIC_CAR]: <FormattedMessage defaultMessage='Turismo | Eléctrico' />,
    [ITV_GAS_LPG_VAN]: <FormattedMessage defaultMessage='Furgoneta | Gasolina GNL/GNC/GLP' />,
    [ITV_DIESEL_VAN]: <FormattedMessage defaultMessage='Furgoneta | Diesel' />,
    [ITV_ELECTRIC_VAN]: <FormattedMessage defaultMessage='Furgoneta | Eléctrico' />,
    [ITV_MOTORCICLE]: <FormattedMessage defaultMessage='Motos y ciclomotores' />,
}

const VehicleInpectionSelectorMessage = ({ value }) => {
    if (!mapMessages[value]) {
        return (
            <FormattedMessage defaultMessage='Tipo de vehículo' />
        )
    }

    return (
        mapMessages[value]
    )
}

VehicleInpectionSelectorMessage.propTypes = {
    value: PropTypes.string,
}

export default VehicleInpectionSelectorMessage
