import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Flex, Box, Copy, Icon } from '@elparking/components'

import {
    LOCALE_DATETIME_FORMAT,
} from 'commons/js/constants'
import { makeHourTimeSpan, makeMinuteTimeSpan, maybeParseTimestamp, toDate } from 'commons/js/util/dateConverter'

import SyncBookingDates from 'commons/js/components/SyncBookingDates'

import PlacesSelect from 'search-box/js/ui/PlacesSelect'
import DateTimeSelect from 'search-box/js/ui/DateTimeSelect'

import IconButton from 'search-box/js/ui/IconButton'
import ShadowBox from 'search-box/js/ui/ShadowBox'
import SeparatorBox from 'search-box/js/ui/SeparatorBox'
import BookingDatesFlex from 'search-box/js/ui/BookingDatesFlex'

import messages from './messages'

const isValid = ({ startDate, endDate }) => startDate !== null && endDate !== null

class AirportForm extends Component {
    static propTypes = {
        intl: PropTypes.object,
        format: PropTypes.string,
        getPlaceById: PropTypes.func,
        autocompleteService: PropTypes.func,
        onSelect: PropTypes.func,
        onChangePlace: PropTypes.func,
        onChangeStartDate: PropTypes.func,
        onChangeEndDate: PropTypes.func,
        formSelector: PropTypes.object,
        dateTimeSelectComponent: PropTypes.object,
    }

    static defaultProps = {
        format: LOCALE_DATETIME_FORMAT,
        onSelect: () => {},
        onChangePlace: () => {},
        onChangeStartDate: () => {},
        onChangeEndDate: () => {},
        formSelector: null,
        dateTimeSelectComponent: DateTimeSelect,
    }

    state = {
        place: null,
        startDate: null,
        endDate: null,
    }

    onChangePlace = this.onChangePlace.bind(this)
    onChangeDates = this.onChangeDates.bind(this)
    onSelect = this.onSelect.bind(this)

    onChangePlace (place) {
        this.setState(
            () => ({
                place,
            }),
            () => {
                this.state.place && this.startDateSelect.focus()
                this.props.onChangePlace(this.state.place)
            }
        )
    }

    onChangeDates (dates) {
        this.setState(() => ({ ...dates }))
    }

    onSelect (event) {
        event.preventDefault()

        if (!isValid(this.state)) {
            this.startDateSelect.focus()
            return
        }

        const { startDate, endDate, place } = this.state
        this.props.getPlaceById({ place_id: place && place.value })
            .then(({ airport_id: airportId }) => {
                this.props.onSelect({
                    startDate: startDate,
                    endDate: endDate,
                    airportId,
                })
            }, () => {
                this.props.onSelect({
                    startDate: startDate,
                    endDate: endDate,
                })
            })
    }

    renderPlacesSelect () {
        const { intl, autocompleteService } = this.props
        const { place } = this.state

        return (
            <PlacesSelect
              dataTest='parking-search-form-places'
              icon='len'
              value={place}
              autocompleteService={autocompleteService}
              placeholder={intl.formatMessage(messages.placePlaceholder)}
              onChange={this.onChangePlace}
              optionRenderer={this.renderPlacesSelectOption}
            />
        )
    }

    renderPlacesSelectOption ({ label }) {
        return (
            <span>
                <Icon type='plane' color='gray' /> {label}
            </span>
        )
    }

    renderStartDateSelect (props, onChange) {
        const { dateTimeSelectComponent: DateTimeSelectComponent } = this.props
        return (
            <DateTimeSelectComponent
              dataTest='parking-search-form-date-start'
              ref={(element) => { this.startDateSelect = element }}
              value={props.value && toDate(maybeParseTimestamp(props.value))}
              placeholder={this.props.intl.formatMessage(messages.startDatePlaceholder)}
              format={this.props.format}
              dayPickerProps={{
                  month: props.month,
                  fromMonth: props.fromMonth,
                  disabledDays: props.disabledDays,
                  locale: this.props.intl.locale,
              }}
              timePickerProps={{
                  disabledHours: props.disabledHours,
              }}
              onChange={onChange}
              onNext={() => this.endDateSelect.focus()}
              showNextButton
            />
        )
    }

    renderEndDateSelect (props, onChange) {
        const { dateTimeSelectComponent: DateTimeSelectComponent } = this.props
        return (
            <DateTimeSelectComponent
              dataTest='parking-search-form-date-end'
              ref={(element) => { this.endDateSelect = element }}
              value={props.value && toDate(maybeParseTimestamp(props.value))}
              placeholder={this.props.intl.formatMessage(messages.endDatePlaceholder)}
              format={this.props.format}
              dayPickerProps={{
                  month: props.month,
                  fromMonth: props.fromMonth,
                  disabledDays: props.disabledDays,
                  locale: this.props.intl.locale,
              }}
              timePickerProps={{
                  disabledHours: props.disabledHours,
              }}
              onChange={onChange}
              onNext={this.onSelect}
              showNextButton
            />
        )
    }

    renderFormSelector () {
        const { formSelector } = this.props
        if (formSelector) {
            return (<Box pl={['0px', '10px']} flex={['0 0 auto', '0 0 auto', '0 0 auto', '0 0 220px']}>
                { formSelector }
            </Box>)
        }

        return null
    }

    render () {
        const { intl } = this.props

        return (
            <SyncBookingDates
              timeSpan={makeHourTimeSpan(4)}
              minTimeSpan={makeMinuteTimeSpan(10)}
              onChange={this.onChangeDates}
              onChangeStart={this.props.onChangeStartDate}
              onChangeEnd={this.props.onChangeEndDate}
            >
                {({ startDateProps, endDateProps, onChangeStart, onChangeEnd }) => (
                    <Flex as='form' flexWrap={['wrap', 'wrap', 'nowrap']} alignItems='stretch'>
                        <ShadowBox flex={['1 0 100%', '1 0 100%', '1 0 auto']} my={['8px', '8px', '10px', '0']}>
                            <Flex alignItems='center'>
                                {this.renderFormSelector()}
                                <Copy dataTest='parking-search-form-places-selector' padding='0' width='100%'>
                                    <Box flex={['1 0 auto', '1 0 auto', '1 0 auto', '1 0 auto']}>
                                        {this.renderPlacesSelect()}
                                    </Box>
                                </Copy>
                            </Flex>
                        </ShadowBox>
                        <ShadowBox flex={['1 0 100%', '1 0 100%', '0 0 320px']} my={['8px', '8px', '0']}>
                            <BookingDatesFlex flexWrap='wrap' alignItems='center'>
                                <SeparatorBox flex={['1 0 50%']}>
                                    {this.renderStartDateSelect(startDateProps, onChangeStart)}
                                </SeparatorBox>
                                <SeparatorBox flex={['1 0 50%']}>
                                    {this.renderEndDateSelect(endDateProps, onChangeEnd)}
                                </SeparatorBox>
                            </BookingDatesFlex>
                        </ShadowBox>
                        <Box flex={['1 0 100%', '1 0 100%', '0 0 80px']} my={['8px', '8px', '0']}>
                            <IconButton dataTest='airport-search-form-search-button' type='right' size='xLarge' color='white' onClick={this.onSelect} className='js-submit'>
                                <span>{intl.formatMessage(messages.submitButton)}</span> <Icon type='right' size='xLarge' color='white' />
                            </IconButton>
                        </Box>
                    </Flex>
                )}
            </SyncBookingDates>
        )
    }
}

export default injectIntl(AirportForm)
