import { hot } from 'react-hot-loader/root'
import React from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import { withScriptjs } from 'commons/js/components/Map'
import { EPProvider } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'

import { defaultLocale, currentLocale } from 'commons/js/locale'
import { messages } from 'commons/js/messages'
import theme from './theme'
import Searcher from './components/Searcher'

const App = ({ filters }) => (
    <IntlProvider defaultLocale={defaultLocale} locale={currentLocale} messages={messages[currentLocale]}>
        <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
            <Searcher filters={filters} />
        </EPProvider>
    </IntlProvider>
)

App.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.string).isRequired,
}

App.defaultProps = {
    filters: ['places', 'airports'],
}

const AppWithScript = withScriptjs(App)

export default hot(AppWithScript)
