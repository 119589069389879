import React from 'react'
import PropTypes from 'prop-types'
import { Copy, Flex, themeColor } from '@elparking/components'

import styled from 'styled-components'

VehicleInspectionSelectorOption.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    vehicleFuels: PropTypes.node,
    imageUrl: PropTypes.string,
    fuelBgColor: PropTypes.string,
}

const VehicleFuelsList = styled(Copy.truncate)`
    background: ${({ fuelBgColor }) => fuelBgColor ? themeColor(fuelBgColor) : 'transparent'};
    border: 1px solid ${themeColor('gray20')};
    display: inline-block;
`

const VehicleIconContainer = styled(Flex)`
    min-width: 60px;
`

const VehicleDataContainer = styled(Flex)`
    min-width: 0;
`

function VehicleInspectionSelectorOption ({title, subtitle, vehicleFuels, imageUrl, fuelBgColor}) {
    return (
        <Flex alignItems='center' width='100%'>
            <VehicleIconContainer px='8px' py='16px' justifyContent='center'>
                <img src={imageUrl} />
            </VehicleIconContainer>
            <VehicleDataContainer flexDirection='column' justifyContent='center' width='100%'>
                {title && <Copy.truncate padding='0' fontWeight='regular' fontSize='medium' color='gray'>{title}</Copy.truncate>}
                {subtitle && <Copy.truncate padding='0' fontWeight='regular' fontSize='xxSmall' color='gray80'>{subtitle}</Copy.truncate>}
                {vehicleFuels &&
                <Flex mt='4px'>
                    <VehicleFuelsList fuelBgColor={fuelBgColor} padding='0 4px 0 4px' fontWeight='book' fontSize='xSmall' color='gray'>{vehicleFuels}</VehicleFuelsList>
                </Flex>
                }
            </VehicleDataContainer>
        </Flex>
    )
}

export default VehicleInspectionSelectorOption
