import React from 'react'
import PropTypes from 'prop-types'

import type Moment from 'moment'

import {
    makeHourTimeSpan,
    makeMinuteTimeSpan,
} from 'commons/js/util/dateConverter'
import {
    getInitialStateFromProps,
    getStartDatePropsFromState,
    getEndDatePropsFromState,
    makeUpdateStartDateProps,
    makeUpdateEndDateProps,
} from './stateChanges'

const DEFAULT_TIME_SPAN = makeHourTimeSpan(1)
const DEFAULT_MIN_TIME_SPAN = makeMinuteTimeSpan(10)

class SyncBookingDates extends React.Component {
    static propTypes = {
        /* eslint-disable react/no-unused-prop-types */
        startDate: PropTypes.number,
        endDate: PropTypes.number,
        timeSpan: PropTypes.number,
        minTimeSpan: PropTypes.number,
        /* eslint-enable react/no-unused-prop-types */
        onChange: PropTypes.func,
        onChangeStart: PropTypes.func,
        onChangeEnd: PropTypes.func,
        children: PropTypes.func,
    }

    static defaultProps = {
        timeSpan: DEFAULT_TIME_SPAN,
        minTimeSpan: DEFAULT_MIN_TIME_SPAN,
        onChange: () => {},
        onChangeStart: () => {},
        onChangeEnd: () => {},
    }

    state = getInitialStateFromProps(this.props)

    onChange = this.onChange.bind(this)
    onChangeStart = this.onChangeStart.bind(this)
    onChangeEnd = this.onChangeEnd.bind(this)

    onChangeStart (selectedDay: Moment) {
        const updateStartDateProps = makeUpdateStartDateProps({
            timeSpan: this.props.timeSpan,
            minTimeSpan: this.props.minTimeSpan,
            selectedDay,
        })

        this.setState(
            updateStartDateProps,
            () => {
                this.props.onChangeStart(this.state.startDate)
                this.onChange()
            }
        )
    }

    onChangeEnd (selectedDay: Moment) {
        const updateEndDateProps = makeUpdateEndDateProps({
            timeSpan: this.props.timeSpan,
            minTimeSpan: this.props.minTimeSpan,
            selectedDay,
        })

        this.setState(
            updateEndDateProps,
            () => {
                this.props.onChangeEnd(this.state.endDate)
                this.onChange()
            }
        )
    }

    onChange () {
        this.props.onChange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        })
    }

    render () {
        return this.props.children({
            startDateProps: getStartDatePropsFromState(this.state),
            endDateProps: getEndDatePropsFromState(this.state),
            onChangeStart: this.onChangeStart,
            onChangeEnd: this.onChangeEnd,
        })
    }
}

export default SyncBookingDates
