import styled from 'styled-components'

import { themeMediaQuery } from '@elparking/components'

export default styled.div`
    width: 100%;
    height: auto;
    ${themeMediaQuery('medium')} {
        height: 80px;
    }
`
