import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Flex, Box, Icon } from '@elparking/components'

import { LOCALE_DATETIME_FORMAT } from 'commons/js/constants'
import { PLACE_TYPE, AIRPORT_TYPE, POI_TYPE } from 'commons/js/services/autocompleteService'
import { toDate, makeHourTimeSpan, makeMinuteTimeSpan, maybeParseTimestamp } from 'commons/js/util/dateConverter'

import SyncBookingDates from 'commons/js/components/SyncBookingDates'

import PlacesSelect from 'search-box/js/ui/PlacesSelect'
import DateTimeSelect from 'search-box/js/ui/DateTimeSelect'
import IconButton from 'search-box/js/ui/IconButton'
import ShadowBox from 'search-box/js/ui/ShadowBox'
import SeparatorBox from 'search-box/js/ui/SeparatorBox'
import BookingDatesFlex from 'search-box/js/ui/BookingDatesFlex'

import messages from './messages'
import MonthSelector from '../MonthSelector'
import styled from 'styled-components'

const isValid = ({ startDate, endDate }) => startDate !== null && endDate !== null

const Block = styled.div`
    display: ${({show}) => show ? 'block' : 'none'};
`

Block.propTypes = {
    show: PropTypes.bool,
}

const getSelectionType = (val) => {
    if (!val) {
        return PLACE_TYPE
    }
    const value = val.value || val
    if (value.startsWith) {
        if (value.startsWith(AIRPORT_TYPE)) {
            return AIRPORT_TYPE
        }

        if (value.startsWith(POI_TYPE)) {
            return POI_TYPE
        }
    }
    return PLACE_TYPE
}

const isAnEventPlace = (place) => place && place.value && (getSelectionType(place.value) === POI_TYPE)

class PlaceForm extends Component {
    static propTypes = {
        intl: PropTypes.object,
        format: PropTypes.string,
        getPlaceById: PropTypes.func,
        autocompleteService: PropTypes.func,
        onSelect: PropTypes.func,
        onChangePlace: PropTypes.func,
        onChangeStartDate: PropTypes.func,
        onChangeEndDate: PropTypes.func,
        formSelector: PropTypes.object,
        dateTimeSelectComponent: PropTypes.object,
    }

    static defaultProps = {
        format: LOCALE_DATETIME_FORMAT,
        onSelect: () => {},
        onChangePlace: () => {},
        onChangeStartDate: () => {},
        onChangeEndDate: () => {},
        formSelector: null,
        dateTimeSelectComponent: DateTimeSelect,
    }

    state = {
        dateType: PLACE_TYPE,
        place: null,
        startDate: null,
        endDate: null,
        month: null,
    }

    onChangePlace = this.onChangePlace.bind(this)
    onChangeDates = this.onChangeDates.bind(this)
    onSelect = this.onSelect.bind(this)

    onChangePlace (place) {
        this.setState(
            () => ({ place }),
            () => {
                if (this.state.place && this.state.place.value) {
                    isAnEventPlace(this.state.place)
                        ? this.monthSelector.focus()
                        : this.startDateSelect.focus()
                }
                this.props.onChangePlace(this.state.place)
            }
        )
    }

    onChangeDates (dates) {
        this.setState(() => ({ ...dates }))
    }

    onSelect (event) {
        event && event.preventDefault()

        if (!isValid(this.state) && !isAnEventPlace(this.state.place)) {
            this.startDateSelect.focus()
            return
        }

        const { startDate, endDate, place, month } = this.state

        this.props.getPlaceById(place && place.value)
            .then((place) => {
                this.props.onSelect({
                    startDate,
                    month,
                    endDate,
                    place,
                })
            }, () => {
                this.props.onSelect({
                    startDate,
                    endDate,
                })
            })
    }

    renderPlacesSelect () {
        const { intl, autocompleteService } = this.props
        const { place } = this.state

        return (
            <PlacesSelect
              dataTest='parking-search-form-places'
              icon='len'
              showGoogleBanner
              value={place}
              autocompleteService={autocompleteService}
              placeholder={intl.formatMessage(messages.placePlaceholder)}
              onChange={this.onChangePlace}
              optionRenderer={this.renderPlacesSelectOption}
            />
        )
    }

    renderPlacesSelectOption ({ label, value }) {
        let iconType = 'location'
        switch (getSelectionType(value)) {
            case AIRPORT_TYPE:
                iconType = 'plane'
                break
            case POI_TYPE:
                iconType = 'event'
                break
        }

        return (
            <Flex alignItems='center'>
                <Icon type={iconType} color='gray' align='baseline' /> <Box pl='0.5em'>{label}</Box>
            </Flex>
        )
    }

    renderStartDateSelect (props, onChange) {
        const { dateTimeSelectComponent: DateTimeSelectComponent } = this.props
        return (
            <Box w='100%'>
                <DateTimeSelectComponent
                  dataTest='parking-search-form-date-start'
                  ref={(element) => { this.startDateSelect = element }}
                  value={props.value && toDate(maybeParseTimestamp(props.value))}
                  placeholder={this.props.intl.formatMessage(messages.startDatePlaceholder)}
                  format={this.props.format}
                  dayPickerProps={{
                      month: props.month,
                      fromMonth: props.fromMonth,
                      disabledDays: props.disabledDays,
                      locale: this.props.intl.locale,
                  }}
                  timePickerProps={{
                      disabledHours: props.disabledHours,
                  }}
                  onChange={onChange}
                  onNext={() => this.endDateSelect.focus()}
                  showNextButton
                />
            </Box>
        )
    }

    renderMonthSelect () {
        return (
            <Box w='100%'>
                <MonthSelector
                  ref={(element) => { this.monthSelector = element }}
                  onChange={(month) => { this.setState({ month }) }}
                  defaultValue={this.state.month}
                />
            </Box>
        )
    }

    renderEndDateSelect (props, onChange) {
        const { dateTimeSelectComponent: DateTimeSelectComponent } = this.props
        return (
            <DateTimeSelectComponent
              dataTest='parking-search-form-date-end'
              ref={(element) => { this.endDateSelect = element }}
              value={props.value && toDate(maybeParseTimestamp(props.value))}
              placeholder={this.props.intl.formatMessage(messages.endDatePlaceholder)}
              format={this.props.format}
              dayPickerProps={{
                    month: props.month,
                    fromMonth: props.fromMonth,
                    disabledDays: props.disabledDays,
                    locale: this.props.intl.locale,
                }}
              timePickerProps={{
                    disabledHours: props.disabledHours,
                }}
              onChange={onChange}
              onNext={this.onSelect}
              showNextButton
            />
        )
    }

    renderFormSelector () {
        const { formSelector } = this.props
        if (formSelector) {
            return (<Box pl={['0px', '10px']} flex={['0 0 auto', '0 0 auto', '0 0 auto', '0 0 220px']}>
                { formSelector }
            </Box>)
        }

        return null
    }

    render () {
        const { intl } = this.props
        return (
            <div>
                <Flex as='form' flexWrap={['wrap', 'wrap', 'nowrap']} alignItems='stretch'>
                    <ShadowBox flex={['1 0 100%', '1 0 100%', '1 0 calc(100% - 630px)']} my={['8px', '8px', '10px', '0']}>
                        <Flex alignItems='center'>
                            {this.renderFormSelector()}
                            <Box flex={['1 0 auto', '1 0 auto', '1 0 auto', '1 0 auto']} width={['calc(100% - 50px)', 'calc(100% - 60px)', 'calc(100% - 205px)']}>
                                {this.renderPlacesSelect()}
                            </Box>
                        </Flex>
                    </ShadowBox>
                    <ShadowBox flex={['1 0 100%', '1 0 100%', '0 0 350px']} my={['8px', '8px', '0']}>
                        <Block key='monthsSelector' show={!!isAnEventPlace(this.state.place)}>
                            <BookingDatesFlex flexWrap='wrap' alignItems='center'>
                                <SeparatorBox flex={['1 0 100%']}>
                                    {this.renderMonthSelect()}
                                </SeparatorBox>
                            </BookingDatesFlex>
                        </Block>
                        <Block key='datesSelector' show={!isAnEventPlace(this.state.place)}>
                            <SyncBookingDates
                              timeSpan={makeHourTimeSpan(4)}
                              minTimeSpan={makeMinuteTimeSpan(10)}
                              onChange={this.onChangeDates}
                              onChangeStart={this.props.onChangeStartDate}
                              onChangeEnd={this.props.onChangeEndDate} >
                                {({ startDateProps, endDateProps, onChangeStart, onChangeEnd }) => (
                                    <BookingDatesFlex flexWrap='wrap' alignItems='center'>
                                        <SeparatorBox flex={['1 0 50%']}>
                                            {this.renderStartDateSelect(startDateProps, onChangeStart)}
                                        </SeparatorBox>
                                        <SeparatorBox flex={['1 0 50%']}>
                                            {this.renderEndDateSelect(endDateProps, onChangeEnd)}
                                        </SeparatorBox>
                                    </BookingDatesFlex>
                                )}
                            </SyncBookingDates>
                        </Block>
                    </ShadowBox>
                    <Box flex={['1 0 100%', '1 0 100%', '0 0 80px']} my={['8px', '8px', '0']}>
                        <IconButton dataTest='parking-search-form-search-button' type='right' size='xLarge' color='white' onClick={this.onSelect} className='js-submit'>
                            <span>{intl.formatMessage(messages.submitButton)}</span> <Icon type='right' size='xLarge' color='white' />
                        </IconButton>
                    </Box>
                </Flex>
            </div>
        )
    }
}

export default injectIntl(PlaceForm)
