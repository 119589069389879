import styled from 'styled-components'
import { Flex, themeMediaQuery } from '@elparking/components'

import SeparatorBox from './SeparatorBox'

export default styled(Flex)`
    position: relative;
    z-index: 0;
    max-width: 100%;
    & > ${SeparatorBox}:first-child:before {
        visibility: hidden;
    }

    ${themeMediaQuery('medium')} {
        & > ${SeparatorBox}:first-child:before {
            visibility: visible;
        }
    }
`
