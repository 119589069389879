import React from 'react'
import PropTypes from 'prop-types'
import DateTimeSelect from 'commons/js/ui/DateTimeSelect'
import styled from 'styled-components'
import {
    themeColor,
    themeFontSize,
    themeFontWeight,
    themeMediaQuery,
} from '@elparking/components'

const DateTimeSelectWrapper = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;

    & > [class^="icon-"]:before {
        position: absolute;
        left: 7px;
        top: 20px;
        padding-left: 5px;
        ${themeMediaQuery('medium')} {
            top: 30px;
        }
    }

    & > div {
        height: 55px;
        padding-left: 0;
        border-radius: 0px;
        background-color: ${themeColor('white')};
        border: none;

        ${themeMediaQuery('medium')} {
            border: 0;
            height: 80px;
        }

        & > div {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: stretch;
            position: relative;
        }

        label > span {
            color: ${themeColor('lightGray')};
            font-weight: ${themeFontWeight('regular')};
            font-size: ${themeFontSize('small')};
            padding-left: 1em;

            display: flex;
            align-items: center;
            height: 100%;
        }

        .DayPickerInput {
            width: 100%;
            height: 100%;
        }

        .DayPickerInputPlaceholder {
            padding: 0;
            font-weight: ${themeFontWeight('regular')};
            color: ${themeColor('lightGray')};
        }

        .DayPickerInputValue {
            padding: 0;
            color: ${themeColor('gray')};
            font-weight: ${themeFontWeight('book')};
            font-size: ${themeFontSize('small')};
            appearance: none;
            outline: 0;
            height: 100%;
        }

        .DayPickerInput input:focus + label > .DayPickerInputPlaceholder {
            color: ${themeColor('main')};
            font-weight: ${themeFontWeight('medium')};
        }

        label > div {
            display: none;
        }

    }
`
const Wrapped = React.forwardRef(({dataTest, ...restProps}, ref) =>
    <DateTimeSelectWrapper dataTest={dataTest}>
        <DateTimeSelect ref={ref} {...restProps} />
    </DateTimeSelectWrapper>
)

Wrapped.propTypes = {
    dataTest: PropTypes.string,
}

Wrapped.defaultProps = {
    dataTest: null,
}

export default Wrapped
