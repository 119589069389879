import styled from 'styled-components'
import { Box, themeColor } from '@elparking/components'

const SEPARATOR_HEIGHT = 30

export default styled(Box)`
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    &::before {
        content: '';
        height: ${SEPARATOR_HEIGHT}px;
        width: 1px;
        top: calc(50% - ${SEPARATOR_HEIGHT / 2}px);
        background: ${themeColor('gray20')};
        z-index: 1;
    }
`
