// Needed to support async await
import 'regenerator-runtime/runtime'
import App from './App'
import render from 'commons/js/util/render'
import { init } from 'commons/js/util/reporter'
import React from 'react'
import ScriptLoader from 'commons/js/util/ScriptLoader'
import { googleMapURL } from 'commons/js/components/Map'

init()

const element = document.querySelector('.js-search-booking-form')
const dataset = element.dataset

const datasetProps = {
    filters: dataset.filters.split(/\W+/),
    selectedFilter: dataset.selectedFilter,
}

const defaultProps = {
    googleMapURL,
    loadingElement: <ScriptLoader />,
}

render({App, element, props: { ...defaultProps, ...datasetProps }})
