import type Moment from 'moment'
import type { ModifierRange } from 'commons/js/services/dateCalculator'

import {
    calculateStartDay,
    calculateEndDay,
    calculateMinStartDay,
    calculateMinEndDay,
    buildBeforeModifier,
} from 'commons/js/services/dateCalculator'
import {
    parseTimestamp,
    maybeParseTimestamp,
    toTimestamp,
    toDate,
} from 'commons/js/util/dateConverter'

type State = {
    startDate: ?number,
    endDate: ?number,
    disabledStartDays: ?Array<ModifierRange>,
    disabledStartHours: ?ModifierRange,
    disabledEndDays: ?Array<ModifierRange>,
    disabledEndHours: ?ModifierRange,
    monthStartDay: ?Date,
    fromMonthStartDay: ?Date,
    monthEndDay: ?Date,
    fromMonthEndDay: ?Date,
}

type StartDatePropsContext = {
    timeSpan: number,
    selectedDay: Moment,
}

type EndDatePropsContext = {
    timeSpan: number,
    minTimeSpan: number,
    selectedDay: Moment,
}

type DateTimeProps = {
    value: ?number,
    disabledDays: Array<ModifierRange>,
    disabledHours: ModifierRange,
    month: Date,
    fromMonth: Date,
}

export const makeUpdateStartDateProps = (context: StartDatePropsContext) => (prevState: State) => {
    const { timeSpan, minTimeSpan, selectedDay } = context

    const startDate = calculateStartDay(selectedDay)
    const endDate = calculateEndDay(
        startDate,
        maybeParseTimestamp(prevState.endDate),
        timeSpan,
        minTimeSpan
    )
    const minStartDate = calculateMinStartDay(toDate(startDate))
    const minEndDate = calculateMinEndDay(toDate(startDate), minTimeSpan)

    return {
        startDate: toTimestamp(startDate),
        endDate: toTimestamp(endDate),
        monthStartDay: toDate(startDate),
        monthEndDay: toDate(startDate),
        fromMonthStartDay: toDate(),
        fromMonthEndDay: minEndDate,
        disabledStartDays: [buildBeforeModifier(minStartDate)],
        disabledStartHours: buildBeforeModifier(minStartDate),
        disabledEndDays: [buildBeforeModifier(minEndDate)],
        disabledEndHours: buildBeforeModifier(minEndDate),
    }
}

export const makeUpdateEndDateProps = (context: EndDatePropsContext) => (prevState: State) => {
    const { minTimeSpan, selectedDay } = context

    const startDate = calculateStartDay(maybeParseTimestamp(prevState.startDate) || selectedDay)
    const endDate = calculateEndDay(
        parseTimestamp(startDate),
        parseTimestamp(selectedDay),
        minTimeSpan,
        minTimeSpan
    )
    const minStartDate = calculateMinStartDay(toDate(startDate))
    const minEndDate = calculateMinEndDay(toDate(startDate), minTimeSpan)

    return {
        startDate: toTimestamp(startDate),
        endDate: toTimestamp(endDate),
        monthEndDay: toDate(selectedDay),
        fromMonthEndDay: minEndDate,
        disabledStartDays: [buildBeforeModifier(minStartDate)],
        disabledStartHours: buildBeforeModifier(minStartDate),
        disabledEndDays: [buildBeforeModifier(minEndDate)],
        disabledEndHours: buildBeforeModifier(minEndDate),
    }
}

export function getStartDatePropsFromState (state: State): DateTimeProps {
    return {
        value: state.startDate,
        disabledDays: state.disabledStartDays,
        disabledHours: state.disabledStartHours,
        month: state.monthStartDay,
        fromMonth: state.fromMonthStartDay,
    }
}

export function getEndDatePropsFromState (state: State): DateTimeProps {
    return {
        value: state.endDate,
        disabledDays: state.disabledEndDays,
        disabledHours: state.disabledEndHours,
        month: state.monthEndDay,
        fromMonth: state.fromMonthEndDay,
    }
}

export function getInitialStateFromProps (props): State {
    const startDate = toDate(maybeParseTimestamp(props.startDate))
    const minStartDate = calculateMinStartDay(startDate)
    const minEndDate = calculateMinEndDay(startDate, props.minTimeSpan)

    return {
        startDate: props.startDate,
        endDate: props.endDate,
        disabledStartDays: [buildBeforeModifier(minStartDate)],
        disabledStartHours: buildBeforeModifier(minStartDate),
        disabledEndDays: [buildBeforeModifier(minEndDate)],
        disabledEndHours: buildBeforeModifier(minEndDate),
        monthStartDay: toDate(maybeParseTimestamp(props.startDate)),
        fromMonthStartDay: toDate(),
        monthEndDay: toDate(),
        fromMonthEndDay: toDate(),
    }
}
