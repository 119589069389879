import styled from 'styled-components'

import { themeMediaQuery, Button } from '@elparking/components'

export default styled(Button)`
  border-radius: 0;
  width: 100%;
  height: 44px;

  & > span {
      padding-right: 10px;
      display: inline-block;
  }

  ${themeMediaQuery('medium')} {
      height: 80px;
      & > span {
          display: none;
      }
  }
`
