import { defineMessages } from 'react-intl'

export default defineMessages({
    placePlaceholder: {
        defaultMessage: 'Escribe tu calle, código postal o lugar',
    },
    submitButton: {
        defaultMessage: 'Buscar',
    },
})
