import React from 'react'
import PropTypes from 'prop-types'
import VehicleInspectionSelectorOption from './VehicleInspectionSelectorOption'
import {
    ITV_GAS_LPG_CAR,
    ITV_DIESEL_CAR,
    ITV_MOTORCICLE,
    ITV_GAS_LPG_VAN,
    ITV_DIESEL_VAN,
    ITV_ELECTRIC_VAN,
    ITV_ELECTRIC_CAR,
} from 'commons/js/constants'
import { FormattedMessage } from 'react-intl'

const optionsConfigurator = {
    [ITV_GAS_LPG_CAR]: {
        title: <FormattedMessage defaultMessage='Turismo' />,
        subtitle: <FormattedMessage defaultMessage='*Turismos, Híbridos, 4x4' />,
        vehicleFuels: <FormattedMessage defaultMessage='Gasolina GNL/GNC/GLP' />,
        imageUrl: '/images/itv/car_itv_dropdown.svg',
        fuelBgColor: 'accent40',
    },
    [ITV_DIESEL_CAR]: {
        title: <FormattedMessage defaultMessage='Turismo' />,
        subtitle: <FormattedMessage defaultMessage='*Turismos, Híbridos, 4x4' />,
        vehicleFuels: <FormattedMessage defaultMessage='Diesel' />,
        imageUrl: '/images/itv/car_itv_dropdown.svg',
        fuelBgColor: 'lightGray20',
    },
    [ITV_ELECTRIC_CAR]: {
        title: <FormattedMessage defaultMessage='Turismo' />,
        vehicleFuels: <FormattedMessage defaultMessage='Eléctrico' />,
        imageUrl: '/images/itv/car_itv_dropdown.svg',
        fuelBgColor: 'white',
    },
    [ITV_GAS_LPG_VAN]: {
        title: <FormattedMessage defaultMessage='Furgoneta {symbol} 3500 kg' values={{symbol: '<'}}/>,
        vehicleFuels: <FormattedMessage defaultMessage='Gasolina GNL/GNC/GLP' />,
        imageUrl: '/images/itv/van_itv_dropdown.svg',
        fuelBgColor: 'accent40',
    },
    [ITV_DIESEL_VAN]: {
        title: <FormattedMessage defaultMessage='Furgoneta {symbol} 3500 kg' values={{symbol: '<'}}/>,
        vehicleFuels: <FormattedMessage defaultMessage='Diesel' />,
        imageUrl: '/images/itv/van_itv_dropdown.svg',
        fuelBgColor: 'lightGray20',
    },
    [ITV_ELECTRIC_VAN]: {
        title: <FormattedMessage defaultMessage='Furgoneta {symbol} 3500 kg' values={{symbol: '<'}}/>,
        vehicleFuels: <FormattedMessage defaultMessage='Eléctrico' />,
        imageUrl: '/images/itv/van_itv_dropdown.svg',
        fuelBgColor: 'white',
    },
    [ITV_MOTORCICLE]: {
        title: <FormattedMessage defaultMessage='Motos y ciclomotores' />,
        imageUrl: '/images/itv/motorcicle_itv_dropdown.svg',
    },
}

VehicleInspectionOptionsSwitcher.propTypes = {
    value: PropTypes.string,
}

function VehicleInspectionOptionsSwitcher ({value}) {
    const optionData = optionsConfigurator[value]
    return <VehicleInspectionSelectorOption
      imageUrl={optionData.imageUrl}
      title={optionData.title}
      subtitle={optionData.subtitle}
      vehicleFuels={optionData.vehicleFuels}
      fuelBgColor={optionData.fuelBgColor}
    />
}

export default VehicleInspectionOptionsSwitcher
