import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Selector, themeColor } from '@elparking/components'
import styled from 'styled-components'

import VehicleInspectionTypeSelector, { makeSelectorOptions } from 'commons/js/components/VehicleInspectionTypeSelector'
const mediaScreenMobile = props => `@media screen and (max-width: ${props.theme.screens.medium})`

const Container = styled.div`
    outline: none;
    width: 100%;
    display: flex;
    align-items: center;
    height: 75px;
    width: 100%;
    ${mediaScreenMobile} {
        height: auto;
    }
`

const VehicleTypeSelectorContainer = styled(Selector)`
    border: 0;
    background: ${themeColor('white')};
    box-shadow: none;
    height: 75px;
    line-height: 80px;
    ${mediaScreenMobile} {
        height: auto;
        line-height: auto;
    }
`

class VehicleTypeSelector extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        defaultValue: PropTypes.any,
    }

    static defaultProps = {
        defaultValue: null,
        onFocus: () => {},
    }

    state = {
        focused: false,
    }

    focus () {
        this.props.onFocus()
        this.dropdown.onOpen()
        this.dropdown.focus()
    }

    focus = this.focus.bind(this)

    render () {
        const { defaultValue } = this.props

        return <Container tabIndex={-1} onFocus={this.focus}>
            <VehicleInspectionTypeSelector
              ref={(el) => { this.dropdown = el }}
              onChange={({ value }) => this.props.onChange(value)}
              isOpen={this.state.focused}
              selectorElement={VehicleTypeSelectorContainer}
              value={defaultValue}
            />
        </Container>
    }
}

export {
    makeSelectorOptions,
}

export default VehicleTypeSelector
