import { navigateTo } from 'commons/js/services/navigate'
import { AIRPORT_TYPE, PLACE_TYPE, POI_TYPE } from 'commons/js/services/autocompleteService'
import Routes from 'commons/js/routes/routes'
import { parseTimestamp, formatDateTime, formatDate, toTimestamp } from 'commons/js/util/dateConverter'
import { MONTH_FORMAT } from 'commons/js/constants'

function mapDates ({ startDate, endDate }) {
    return {
        start_date: startDate && formatDateTime(parseTimestamp(startDate)),
        end_date: endDate && formatDateTime(parseTimestamp(endDate)),
    }
}

export function navigateToPlaces ({ placeId, ...dates }) {
    navigateTo(Routes.parkingsSearchPath({
        ...mapDates(dates),
        place_id: placeId,
    }))
}

export function navigateToAirports ({ airportId, ...dates }) {
    navigateTo(Routes.parkingsSearchPath({
        ...mapDates(dates),
        airport_id: airportId,
        'parking_type[airport]': true,
    }))
}

export function navigateToVehicleInspectionStation ({ placeId, vehicleType }) {
    navigateTo(Routes.vehicleInspectionStationSearchPath({
        vehicle_filter: vehicleType,
        place_id: placeId,
    }))
}

export function navigateToElectricChange ({ placeId }) {
    navigateTo(Routes.chargingPointSearchPath({
        place_id: placeId,
    }))
}

export function navigateToPoiEvents ({ poiId, month }) {
    navigateTo(Routes.poiEventsPath({
        month: formatDate(parseTimestamp(month || toTimestamp()), MONTH_FORMAT),
        poiId: poiId,
    }))
}

export function navigateToAny (params) {
    const { place, ...restParams } = params
    const { type, payload } = place || { type: PLACE_TYPE, payload: {} }

    if (type === PLACE_TYPE) {
        return navigateToPlaces({
            ...restParams,
            placeId: payload.place_id,
        })
    }

    if (type === POI_TYPE) {
        return navigateToPoiEvents({
            month: restParams.month,
            poiId: payload.poi_id,
        })
    }

    if (type === AIRPORT_TYPE) {
        return navigateToAirports({
            ...restParams,
            airportId: payload.airport_id,
        })
    }

    return navigateToPlaces({
        ...restParams,
    })
}
