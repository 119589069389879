import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'

import PlacesSelect from 'commons/js/ui/PlacesSelect'
import { Icon,
    themeColor,
    themeFontSize,
    themeFontWeight,
    themeMediaQuery,
} from '@elparking/components'

const PlacesSelectWrapper = styled.div`
    max-width: calc(100% - 20px);
    width:  calc(100% - 20px);
    min-width: calc(100% - 20px);
    height: 100%;
    position: relative;

    ${themeMediaQuery('xLarge')} {
        right: 0;
    }

    ${themeMediaQuery('medium')} {
        position: relative;
    }

    & > i:before {
        display: none;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7px;
        padding-left: 1em;
        font-size: 15px;
        ${themeMediaQuery('xLarge')} {
            display: inline-block;
        }
    }

    & > div {
        display: inline-block;
        padding-left: 0em;
        border: 0;
        height: 55px;
        position: initial;
        ${themeMediaQuery('medium')} {
            position: relative;
        }


        .Select__placeholder {
            padding: 0 0 0 1em;
            height: 55px;
            line-height: 55px;
            color: ${themeColor('lightGray')};
            font-weight: ${themeFontWeight('regular')};
        }

        .Select__value-container .Select__placeholder,
        .Select__value-container .Select__single-value,
        .Select__input-container {
            ${themeMediaQuery('medium')} {
                padding-right: 2em;
                padding-left: 2em;
            }
            width: 100%;
        }

        .Select__value-container .Select__single-value,
        .Select__input-container {
            font-weight: ${themeFontWeight('book')};
        }

        &.Select__single > .Select__control .Select__value {
            padding: 0 0 0 1em;
            height: 55px;
            line-height: 55px;
        }

        .Select__control {
            outline: none;
            margin: 0;
            position: inherit;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            box-shadow: none;
            min-width: calc(100% - 1px);
            max-width: fit-content;
        }

        .Select__multi-value-wrapper {
            overflow: hidden;
        }

        .Select__control,
        &.is-focused > .Select__control {
            color: ${themeColor('gray')};
            font-size: ${themeFontSize('small')};
            border-radius: 0;
            box-shadow: none;
        }

        &.is-focused > .Select__control {
            border: 0;
            border-bottom: 4px solid ${themeColor('main')};
            border-top: 4px solid transparent;
        }

        .Select__option.is-focused {
            background-color: ${(props) => transparentize(0.6, themeColor('accent')(props))};
            color: ${themeColor('gray')};
        }

        .Select__option.is-selected {
            background-color: ${themeColor('accent')};
        }

        &.Select__single > .Select__control .Select__placeholder {
            color: ${themeColor('lightGray')};
            font-weight: ${themeFontWeight('regular')};
        }

        &.Select__single > .Select__control .Select__placeholder,
        &.Select__single > .Select__control .Select__value {
            display: flex;
            align-items: center;
            height: auto;
            padding-top: 0;
        }

        .Select__value-label,
        .Select__input > input,
        &.has-value.Select__single > .Select__control .Select__value .Select__value-label,
        &.has-value.is-pseudo-focused.Select__single > .Select__control .Select__value .Select__value-label {
            color: ${themeColor('gray')};
            font-size: ${themeFontSize('medium')};
            font-weight: ${themeFontWeight('book')};

            ${themeMediaQuery('medium')} {
                font-size: ${themeFontSize('small')};
            }
        }

        .Select__value-label {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .Select > .icon-search {
            display: none;
        }

        .Select__menu-outer {
            left: 0;
        }

        .Select__menu {
           left: 0;
        }

        ${themeMediaQuery('xLarge')} {
            .Select__placeholder,
            .Select__input,
            &.Select__single > .Select__control .Select__value {
                padding-left: 45px;
            }

            .Select__menu {
                margin-left: 0;
            }

            input.Select__input {
                padding-left: 32px;
            }

            height: ${({maxHeight}) => maxHeight}
        }
    }
`

PlacesSelectWrapper.propTypes = {
    maxHeight: PropTypes.string,
}

const Wrapped = ({ icon, maxHeight, ...restProps }) => {
    return (
        <PlacesSelectWrapper maxHeight={maxHeight}>
            <PlacesSelect {...restProps} />
            <Icon size='large' type={icon} color='gray' />
        </PlacesSelectWrapper>
    )
}

Wrapped.propTypes = {
    icon: PropTypes.string,
    maxHeight: PropTypes.string,
}

Wrapped.defaultProps = {
    icon: PropTypes.string,
    maxHeight: '80px',
}

export default Wrapped
