import { defineMessages } from 'react-intl'

export default defineMessages({
    placePlaceholder: {
        defaultMessage: 'Introduce el aeropuerto',
    },
    startDatePlaceholder: {
        defaultMessage: 'Entrada',
    },
    endDatePlaceholder: {
        defaultMessage: 'Salida',
    },
    submitButton: {
        defaultMessage: 'Encuentra aparcamiento',
    },
})
