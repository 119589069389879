import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import {
    makeAggregatedAutocomplete,
    makeAirportsAutocomplete,
    makePlacesAutocomplete,
} from 'commons/js/services/autocompleteService'
import {
    reverseSearch,
    reverseGeocodeAirport,
} from 'commons/js/services/geocoderService'
import {
    trackParkingsChangeType,
    trackParkingsChangePlaceEvent,
    trackParkingsChangeStartDateEvent,
    trackParkingsChangeEndDateEvent,
    trackParkingsSearchEvent,
    trackVehicleInspectionStationSearchEvent,
    trackVehicleInspectionStationSearchChangePlaceEvent,
    trackVehicleInspectionStationSearchChangeVehicleTypeEvent,
    trackElectricChangeSearchEvent,
    trackElectricChangeSearchChangePlaceEvent,
} from 'commons/js/services/analytics'
import {
    navigateToElectricChange,
    navigateToVehicleInspectionStation,
    navigateToAny,
    navigateToAirports,
} from 'search-box/js/services/navigateTo'

import Container from '../../ui/Container'
import PlaceForm from '../PlaceForm'
import AirportForm from '../AirportForm'
import ElectricChargeForm from '../ElectricChargeForm'
import VehicleInspectionForm from '../VehicleInspectionForm'
import FormSelector from './FormSelector'
import messages from './messages'

const FILTER_ALL = 'all'
const FILTER_PLACES = 'places'
const FILTER_AIRPORTS = 'airports'
const FILTER_VI = 'vehicleinspection'
const FILTER_ELECTRIC_CHAGE = 'electriccharge'

class Searcher extends React.Component {
    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.string),
        selectedFilter: PropTypes.oneOf([FILTER_ALL, FILTER_PLACES, FILTER_AIRPORTS]),
    }

    static defaultProps = {
        filters: [FILTER_ALL, FILTER_PLACES, FILTER_AIRPORTS],
    }

    state = {
        selectedFilter: this.props.selectedFilter || this.props.filters[0],
    }

    selectOption = this.selectOption.bind(this)

    selectOption (option) {
        this.setState(
            () => ({ selectedFilter: option.value }),
            () => { trackParkingsChangeType() }
        )
    }

    getFormProps = this.getFormProps.bind(this)

    getFormProps (filter) {
        const { intl } = this.props

        const propsMap = {
            [FILTER_ELECTRIC_CHAGE]: {
                getPlaceById: reverseSearch,
                autocompleteService: makePlacesAutocomplete(),
                onSelect: (params) => {
                    navigateToElectricChange(params)
                    trackElectricChangeSearchEvent()
                },
                onChangePlace: trackElectricChangeSearchChangePlaceEvent,
            },
            [FILTER_VI]: {
                getPlaceById: reverseSearch,
                autocompleteService: makePlacesAutocomplete(),
                onSelect: (params) => {
                    navigateToVehicleInspectionStation(params)
                    trackVehicleInspectionStationSearchEvent()
                },
                onChangePlace: trackVehicleInspectionStationSearchChangePlaceEvent,
                onChangeVehicleType: trackVehicleInspectionStationSearchChangeVehicleTypeEvent,
            },
            [FILTER_ALL]: {
                getPlaceById: reverseSearch,
                autocompleteService: makeAggregatedAutocomplete({ formatMessage: intl.formatMessage }),
                onSelect: (params) => {
                    navigateToAny(params)
                    trackParkingsSearchEvent(params)
                },
                onChangePlace: trackParkingsChangePlaceEvent,
                onChangeStartDate: trackParkingsChangeStartDateEvent,
                onChangeEndDate: trackParkingsChangeEndDateEvent,
            },
            [FILTER_AIRPORTS]: {
                getPlaceById: reverseGeocodeAirport,
                autocompleteService: makeAirportsAutocomplete({ formatMessage: intl.formatMessage }),
                onChangePlace: trackParkingsChangePlaceEvent,
                onChangeStartDate: trackParkingsChangeStartDateEvent,
                onChangeEndDate: trackParkingsChangeEndDateEvent,
                onSelect: (params) => {
                    navigateToAirports(params)
                    trackParkingsSearchEvent(params)
                },
            },
        }

        return propsMap[filter]
    }

    getFormElement (filter) {
        const nullElement = () => null
        const elementMap = {
            [FILTER_VI]: VehicleInspectionForm,
            [FILTER_ELECTRIC_CHAGE]: ElectricChargeForm,
            [FILTER_ALL]: PlaceForm,
            [FILTER_PLACES]: PlaceForm,
            [FILTER_AIRPORTS]: AirportForm,
        }

        return elementMap[filter] || nullElement
    }

    getFormSelectorOptions = this.getFormSelectorOptions.bind(this)

    // TODO: Move to FilterSelector component
    getFormSelectorOptions (filter) {
        const { intl } = this.props

        const optionMap = {
            [FILTER_ELECTRIC_CHAGE]: {
                dataTest: 'search_electric-charge_option',
            },
            [FILTER_VI]: {
                dataTest: 'search_vehicle-inspection_option',
            },
            [FILTER_ALL]: {
                dataTest: 'search_all_option',
                icon: '/images/icons/ic_search_parkings.svg',
                label: intl.formatMessage(messages.allFilterLabel),
                value: FILTER_ALL,
            },
            [FILTER_PLACES]: {
                dataTest: 'search_places_option',
                icon: '/images/icons/ic_search_parkings.svg',
                label: intl.formatMessage(messages.placesFilterLabel),
                value: FILTER_PLACES,
            },
            [FILTER_AIRPORTS]: {
                dataTest: 'search_airports_option',
                icon: '/images/icons/ic_plane.svg',
                label: intl.formatMessage(messages.airportsFilterLabel),
                value: FILTER_AIRPORTS,
            },
        }

        return optionMap[filter]
    }

    // TODO: Move to FilterSelector component
    renderFormSelector (selected) {
        const { filters } = this.props
        const shouldShowFilters = filters.length > 1

        if (!shouldShowFilters) {
            return null
        }

        const options = filters.map(this.getFormSelectorOptions)

        return (
            <FormSelector
              selected={selected}
              onChange={this.selectOption}
              options={options}
            />
        )
    }

    render () {
        const { selectedFilter } = this.state

        const Form = this.getFormElement(selectedFilter)
        const formProps = this.getFormProps(selectedFilter)

        return (
            <Container>
                <Form
                  formSelector={this.renderFormSelector(selectedFilter)}
                  {...formProps}
                />
            </Container>
        )
    }
}

Searcher.propTypes = {
    intl: PropTypes.object,
}

export default injectIntl(Searcher)
