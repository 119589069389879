import { defineMessages } from 'react-intl'

export default defineMessages({
    placePlaceholder: {
        defaultMessage: 'Ej.: Calle, ciudad...',
    },
    startDatePlaceholder: {
        defaultMessage: 'Entrada',
    },
    endDatePlaceholder: {
        defaultMessage: 'Salida',
    },
    submitButton: {
        defaultMessage: 'Busca tu plaza',
    },
})
